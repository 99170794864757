[class*=illustration-] {
	position: relative;

	&::after {
		pointer-events: none;
	}
}

[class*=illustration-element-]::after {
	z-index: -1;
}

[class*=illustration-section-]::after {
	z-index: -2;
}

@media (min-width: 641px) {
	.section-inner {
	    padding-top: 80px;
	    padding-bottom: 40px;
	}
}

// Hero illustration 01
.illustration-section-01::after {
	@include illustration('illustration-section-01.svg', 100%, 612px, top);
}

// Hero illustration 02
.illustration-section-02::after {
	@include illustration('illustration-section-02.svg', 100%, 612px, top);
}

// Hero illustration Bio
@media (max-width: 704px) {
	.illustration-section-bio::after {
		@include illustration('bioc.png', 100%, 100%, top);
		background-size: cover;
		background-position: -122px top;
	}
}


@media (min-width: 705px) {
	.illustration-section-bio::after {
		@include illustration('bioc.png', 100%, 100%, top);
		background-size: cover;
		background-position: left top;
	}
}

// Hero illustration Coronavirus
.illustration-section-covid::after {
	@include illustration('coronavirus-transparent.png', 100%, 612px, top);
		left: 50%;
	  top: -76%;
}
