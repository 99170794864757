
body {
	// background: #2d1449;
  background: linear-gradient(0deg, black, #371759) no-repeat;
}

.footer-nav {
    padding-top: 43px;
}

.footer-social li {
    padding: 0 2px;
}

.clients .section-inner {
    padding-top: 12px;
    padding-bottom: 12px;
}

.has-top-divider::before {
    background: #40155f !important;
}

.testimonial-date {
    text-transform: uppercase;
    font-size: 60%;
    font-weight: 900;
    opacity: 50%;
}

// FOOTER

.site-footer-with-contact::before {
    height: 90%;
}

.cta-action div {
    padding-bottom: 5px;
}

// ROADMAP

// Roadmap Moonshot
@media (min-width: 641px) {
  .roadmap {
      // fallback
      background-image: url('../../../../assets/images/moon.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-size: 154px;
      background-position-x: 14%;
      background-position-y: bottom;

  }
}

@media (max-width: 641px) {
  .roadmap {
      background-image: url('../../../../assets/images/moon.png');
      background-repeat: no-repeat;
      background-attachment: scroll;
      background-color: rgba(0, 0, 0, 0.1);
      background-size: 154px;
      background-position-x: -23%;
      background-position-y: 100%;
  }
}

// Roadmap future
[class^="future"].timeline-item-content {
    color: #979db8;
}

// Roadmap last item

.timeline-item:last-child {
    padding-bottom: 48px;
}

// Roadmap current node
[class^="current"].timeline-item:not(:last-of-type)::before {
    top: 51px;
    height: calc(100% - 10px - 16px);
}

[class^="past"].timeline-item:not(:last-of-type)::before {
    height: calc(100% - 10px - 18px);
}

@media (min-width: 641px) {
  [class^="current"].timeline-item:not(:last-of-type)::before {
      top: 58px;
      height: calc(100% - 10px - 16px);
  }
}

@media (min-width: 641px) {
  [class^="past"].timeline-item:not(:last-of-type)::before {
      height: calc(100% - 10px - 18px);
  }
}

[class^="current"].timeline-item-header::before {
    top: -2px;
    left: -42px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: #376DF9;
}

[class^="future"].timeline-item-header::before {
    background: #873154;
}

@media (min-width: 641px) {
    .timeline-item:nth-child(odd) [class^="current"].timeline-item-header::before {
        background: #376DF9;
        left: -46px;
        top: -2px;
    }
}

@media (min-width: 641px) {
    .timeline-item:nth-child(even) [class^="current"].timeline-item-header::before {
        background: #376DF9;
        left: auto;
        right: -46px;
        top: -3px;
    }
}

@media (min-width: 641px) {
    .tiles-quote-only {
      text-align: left;
      width: 400%;
      margin-top: -20px;
      margin-left: 25%;
    }
}

@media (max-width: 640px) {
    .tiles-quote-only {
      text-align: left;
      margin-left: 5%;
    }
}
